import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { getMaskedPhoneValue } from 'src/formio/utils/getDisabledValue';
import { LeadBrief } from 'src/generated-api-client';
import { FullNameHelper } from 'src/utils/FullNameHelper';

type LeadsListItemProps = { item: LeadBrief };

export const LeadsListItem = observer(({ item }: LeadsListItemProps) => {
    const fullName = FullNameHelper(item);

    return (
        <MasterDetailsAsideItem to={EntityLinks.leads.itemDetails(item.id)}>
            <MainContainer>
                <Tooltip title={fullName}>
                    <EllipsisContainer>
                        <MainEntityListItemText className="strong-text">
                            {fullName}
                        </MainEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
            </MainContainer>
            <SecondaryContainer>
                <Tooltip title={getMaskedPhoneValue(item?.phone || '')}>
                    <EllipsisContainer>
                        <SecondaryEntityListItemText>
                            <span className="strong-text">
                                {getMaskedPhoneValue(item?.phone || '')}
                            </span>
                        </SecondaryEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
                <Tooltip title={item.email}>
                    <EllipsisContainer>
                        <SecondaryEntityListItemText>
                            {item.email}
                        </SecondaryEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
            </SecondaryContainer>
        </MasterDetailsAsideItem>
    );
});
