import Components from 'formiojs/components/Components';
import _ from 'lodash';

const DatagridComponent = (Components as any).components.datagrid;

class Datagrid extends (DatagridComponent as any) {
    checkRefresh(refreshData: any, changed: any) {
        const changePath = _.get(changed, 'instance.path', false);
        if (changePath && this.path === changePath) {
            this.rebuild();
        }
        if (
            refreshData === 'data' &&
            this.getValue().length &&
            changed?.instance?.parent.currentForm?.submissionSet
        ) {
            this.rebuild();
        }
    }
}

export default Datagrid;
